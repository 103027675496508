<template>
  <div class="member page-container">
    <PageTitle title="點數查詢" />
    <div class="points">
      <div class="points_row total">
        <p class="points_text">總點數</p>
        <p class="points_text point">
          <span>{{ pointsData.totalPoints }}</span> 點
        </p>
      </div>
      <div class="points_row">
        <p class="points_text">累計登錄金額</p>
        <p class="points_text">NT$ {{ totalPrice }} 元</p>
      </div>
    </div>
    <div class="invoice_list" v-if="this.invoiceData.length > 0">
      <div
        v-for="(item, i) in showInvoiceData"
        :key="i"
        class="invoice_list_item"
      >
        <BaseIcon
          class="invoice_list_item_icon mr-5"
          :name="item.icon"
          width="50px"
          height="50px"
        />
        <div class="invoice_list_item_content">
          <p>發票號碼：{{ item.invoiceNumber }}</p>
          <p>登錄日期：{{ item.invoiceDate }}</p>
          <p v-if="item.icon !== 'search'">商品金額：{{ item.price }}元</p>
          <p v-if="item.icon === 'search'">審核中</p>
          <p v-if="item.failMessage">失敗原因：{{ item.failMessage }}</p>
        </div>
      </div>
    </div>
    <div class="panel_list" v-if="this.invoiceData.length > 0">
      <div class="page_btn go_first" @click="goFirst"><img src="@/assets/img/go_first.svg" alt=""> </div>
      <div class="page_btn go_prev" @click="goPrev"> <img src="@/assets/img/go_prev.svg" alt=""> </div>
      <div class="page" v-for="i in pages" :key="i" :class="{ 'active': nowPage === i}" @click="toPage(i)">{{i}}</div>
<!--      <div class="page">...</div>-->
      <div class="page_btn go_next" @click="goNext"><img src="@/assets/img/go_next.svg" alt=""></div>
      <div class="page_btn go_last" @click="goLast"><img src="@/assets/img/go_last.svg" alt=""></div>
    </div>
    <div style="margin-top: 30px;" v-if="this.invoiceData.length <= 0"></div>
    <PageBtnSet />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PageBtnSet from "@/components/PageBtnSet.vue";
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  name: "Member",
  components: {
    PageTitle,
    PageBtnSet,
    BaseIcon,
  },
  data() {
    return {
      invoiceData: [],
      pointsData: {},
      totalPrice: 0,
      pages: 0,
      nowPage: 1,
      ops: {
        vuescroll: {
          mode: "slide",
          sizeStrategy: "percent",
          detectResize: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right",
        },
        rail: {},
        bar: {
          keepShow: true,
          background: "#c4c4c4",
          opacity: 1,
        },
      },
    };
  },
  created() {
    this.getData();
  },
  computed:{
    showInvoiceData(){
       let data = this.invoiceData.filter(item =>{
         return item.index === this.nowPage
       })

      return data
    }
  },
  methods: {
    async getData() {
      this.$store.commit("updateLoading", true);
      let url = process.env.VUE_APP_APIUrl + "api/v1/users/get-user-token";
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      };
      await this.axios
        .post(url, data)
        .then((res) => {
          window.localStorage.setItem("jwt", res.data.data.token);
          this.getInvoiceList();
          this.getPointsList();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInvoiceList() {
      let url = process.env.VUE_APP_APIUrl + "api/v1/auth/invoices/list";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          this.invoiceData = res.data.data;
          let sum = 0;
          this.pages = Math.ceil(this.invoiceData.length/10)
          this.invoiceData.forEach((item, i) => {
            item.index = Math.ceil((i + 1) / 10 )
            sum = sum + Number(item.price);
            if (item.status === "登錄成功") {
              item.icon = "check";
            } else if (item.status === "待審核") {
              item.icon = "search";
            } else {
              item.icon = "cross";
            }
          });
          let internationalNumberFormat = new Intl.NumberFormat("en-US");
          this.totalPrice = internationalNumberFormat.format(sum);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPointsList() {
      let url = process.env.VUE_APP_APIUrl + "api/v1/auth/points/list";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          this.pointsData = res.data.data;
          this.$store.commit("updateLoading", false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goFirst(){
      this.nowPage = 1
    },
    goLast(){
      this.nowPage = this.pages
    },
    goPrev(){
      if(this.nowPage > 1){
        this.nowPage--
      }
    },
    goNext(){
      if(this.nowPage < this.pages){
        this.nowPage++
      }
    },
    toPage(page){
      if(page !== this.nowPage){
        this.nowPage = page
      }
    }
  },
};
</script>

<style lang="scss">
.member {
  .points {
    width: 100%;
    background-color: #efefef;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 12px 15px;
    .points_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
      .points_text {
        color: #333;
        font-weight: 400;
      }
      &.total {
        align-items: flex-end;
        .points_text {
          font-weight: 500;
          &.point {
            color: #c3131b;
            span {
              font-size: 22px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .invoice_list {
    margin: 35px 0 0px;
    padding: 0 6px;
    width: 100%;
    overflow-y: auto;
    &_item {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #888888;
      &:last-child {
        border-bottom: 0;
      }
      &_icon {
        flex-shrink: 0;
      }
      &_content {
        p {
          text-align: left;
        }
      }
    }
  }
}

.panel_list{
  height: 30px;
  width: 267px;
  display: inline-flex;
  justify-content: space-around;
  align-content: center;
  margin: 20px 0px;
  .page{
    color: white;
  }
  .page_btn{
    width: 30px;
    height: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .active{
    font-weight: bold;
  }
}
</style>
